<template>
  <a-card
    :loading="isLoading"
    size="small"
    hoverable
    class="d-flex flex-column flex-grow-1 mt-2"
    :body-style="{
      padding: '0',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }"
  >
    <template #title>
      <!-- <a-select
        v-model:value="selectedTag"
        class="w-50"
        size="small"
        placeholder="Select Tag"
        show-search
        :options="tagOptions"
        :filter-option="filterOption"
      /> -->
      <a-select
        v-model:value="selectedTag"
        mode="tags"
        size="small"
        style="width: 95%"
        :token-separators="[',']"
        placeholder="Select tag"
        :filter-option="filterOption"
        :options="tagOptions"
      />
    </template>
    <template #extra>
      <a-button
        size="small"
        :disabled="!selectedTag"
        :loading="isAddingTags"
        @click="addTimeStampTags"
      >
        Add
      </a-button>
    </template>
    <a-list
      class="tags-list"
      size="small"
      item-layout="horizontal"
      :data-source="timeStampTags"
      style="flex-grow: 1; height: 1px; overflow-y: auto"
    >
      <template #renderItem="{ item }">
        <a-list-item @click="handleTagClick(item.timeStamp)">
          <template #actions>
            <a-popconfirm
              :title="`Are you sure you want to detach this tag?`"
              ok-text="Yes"
              cancel-text="No"
              @confirm="removeTimeStampTags(item.id)"
            >
              <a-space>
                <a-tooltip title="Remove">
                  <DeleteOutlined />
                </a-tooltip>
              </a-space>
            </a-popconfirm>
          </template>
          <a-list-item-meta
            :title="item.tag.name"
            :description="
              role == roles.support_user &&
              `${item.added_by} added ${item.created_at}`
            "
          />

          <div>{{ item.timeStamp }}</div>
        </a-list-item>
      </template>
    </a-list>
  </a-card>
</template>
<script>
import { DeleteOutlined } from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timeHelper from 'src/components/shared/Helpers/timeHelper';
import { roles } from 'src/config/roles-config';
import TelemetryService from 'src/services/telemetry';
import VideoService from 'src/services/videos';
import { mapActions, mapGetters } from 'vuex';
dayjs.extend(relativeTime);

export default {
  components: {
    DeleteOutlined,
  },
  inject: ['toast', 'deepClone'],
  props: ['taskRecordId', 'currentVideoTime'],
  emits: ['skipTo'],
  setup() {
    return { roles };
  },
  data() {
    return {
      isLoading: false,
      isAddingTags: false,
      selectedTag: [],
      timeStampTags: [],
    };
  },
  computed: {
    ...mapGetters([
      'organization',
      'organizationTags',
      'role',
      'tagIdToNameMap',
    ]),
    tagOptions() {
      return this.organizationTags.map((el) => ({
        value: el.id,
        label: el.tag_name,
      }));
    },
  },
  mounted() {
    this.getTimeStampTags();
    this.fetchOrgTags();
  },
  methods: {
    ...mapActions(['fetchOrganizationTags', 'addNewTag', 'setNewTags']),
    convertToMmSs(timeStamp) {
      return timeHelper.convertToMMSS(timeStamp?.split('.')[0]);
    },

    fetchOrgTags() {
      if (this.organizationTags.length) return;
      this.fetchOrganizationTags();
    },

    async getTimeStampTags() {
      this.isLoading = true;
      const [error, data] = await TelemetryService.getTimeStampTags(
        this.taskRecordId
      );
      if (error) {
        this.toast.error('Unable to fetch tags!');
        this.isLoading = false;
        return;
      }
      this.timeStampTags = data.map((el) => ({
        ...el,
        timeStamp: this.convertToMmSs(el.time_stamp),
        created_at: dayjs(new Date(el.created_at)).fromNow(),
      }));
      this.isLoading = false;
    },

    checkIfTagExist(tag) {
      const currentTime = this.convertToMmSs(
        timeHelper.formatSecondsToHhMmSs(this.currentVideoTime)
      );
      return this.timeStampTags.some(
        (el) => el.tag.id === tag && el.timeStamp === currentTime
      );
    },

    async createNewTag(tagName) {
      const maxLength = 30;
      const validCharsPattern = /^[a-zA-Z0-9 _-]+$/;

      if (tagName.length > maxLength) {
        this.toast.error(`Tag must be ${maxLength} characters or fewer.`);
        return;
      }

      if (!validCharsPattern.test(tagName)) {
        this.toast.error(
          'Tag can only contain letters, numbers, spaces, hyphens (-), and underscores (_).'
        );
        return;
      }

      const payload = {
        tag_name: tagName,
        descript: 'default',
        organization: this.organization,
      };
      const [error, data] = await VideoService.createOrganizationTag(payload);
      if (error) {
        this.toast.error(`Unable to create '${tagName}'`);
        return;
      }
      this.setNewTags({ id: data.id, tag_value: data.tag_name });
      this.addNewTag(data);
      return data;
    },

    async addTimeStampTags() {
      this.isAddingTags = true;
      const requests = Object.values(this.selectedTag).map(
        this.createTimeStampTag
      );
      const responses = await Promise.all(requests);
      this.isAddingTags = false;
      if (responses.every((e) => !e)) return;

      this.selectedTag = [];

      this.getTimeStampTags();
    },

    createTimeStampTag(tag) {
      return new Promise(async (resolve) => {
        if (typeof tag === 'string') {
          const newTag = await this.createNewTag(tag);
          if (!newTag) return resolve(false);
          tag = newTag.id;
        }

        const payload = {
          tag: tag,
          organization: this.organization,
          task_record: this.taskRecordId,
          time_stamp: timeHelper.formatSecondsToHhMmSs(this.currentVideoTime),
          added_by: localStorage.getItem('id'),
        };

        if (this.checkIfTagExist(tag)) {
          this.toast.info(
            `${this.tagIdToNameMap[tag]} tag has already been added at this time!`
          );
          return resolve(false);
        }

        const [error] = await TelemetryService.addTimeStampTags(payload);
        if (error) {
          this.toast.error(`Unable to add ${this.tagIdToNameMap[tag]}!`);
          return resolve(false);
        }
        return resolve(true);
      });
    },

    async removeTimeStampTags(id) {
      const tempTags = this.deepClone(this.timeStampTags);
      const itemIndex = tempTags.findIndex((el) => el.id === id);
      this.timeStampTags = this.timeStampTags.filter(
        (_, idx) => idx !== itemIndex
      );

      const [error] = await TelemetryService.removeTimeStampTags(id);
      if (error) {
        this.toast.error('Unable to fetch tags!');
        this.timeStampTags = tempTags;
        return;
      }
    },

    handleTagClick(timeStamp) {
      const timeToSkipTo = timeHelper.formatMmSsToSeconds(timeStamp);
      this.$emit('skipTo', timeToSkipTo);
    },

    filterOption(input, option) {
      return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
  },
};
</script>
<style>
.tags-list .ant-list-item:hover {
  background: lightgray;
}
</style>
